:root {
  --width: 420px;
}
* {
  box-sizing: border-box;
  position: relative;
}
body {
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}
.App {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100vh;
  align-items: center;
}
.iphone {
  max-width: var(--width);
  width: var(--width);
  height: 803px;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.email-config {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid;
  background-color: #f3f2f8;
  gap: 32px;
  border-radius: 40px;
  overflow: hidden;
  flex: 1;
  padding: 20px 0;
}
.toggle {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}
.toggle div {
  background-color: #f3f2f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 6px;
  border-radius: 30px;
  width: 100%;
}
.toggle div span {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  padding: 4px 0;
}
.toggle div span.active {
  background-color: white;
}
section {
  display: flex;
  gap: 6px;
  flex-direction: column;
}

h6 {
  font-size: 14px;
  margin: 0;
  padding: 0 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .2px;
  color: #86858b;
}
fieldset {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  border-left: 0;
  border-right: 0;
}

fieldset div {
  padding: 10px 10px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #DDD;
}
fieldset div:last-of-type {
  border-bottom: 0;
}

.placeholder {
  color: #AAA;
}

input {
  border: none;
  font-size: 16px;
  flex: 1;
  outline: none;
}
input::placeholder {
  color: red;
}
